
import { Component, Vue } from "vue-property-decorator";
import { get } from "@/api";
import ChallengeCard from "@/components/challenges/ChallengeCard.vue";
import { ChallengeShortInfo, PurchaseChallenge } from "@/interfaces/challenge";
import { VueperSlides, VueperSlide } from "vueperslides";
import i18n from "@/i18n";
const pageKey = "purchased-challenges";

@Component({
  components: {
    ChallengeCard,
    VueperSlides,
    VueperSlide,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class ChallengesOverview extends Vue {
  challenges: ChallengeShortInfo[] = [];
  purchasedChallenges: PurchaseChallenge[] = [];
  notPurchasedChallenges: ChallengeShortInfo[] = [];
  isUserPickingLang = false;
  pickedLanguage = this.startingLanguage;

  mounted() {
    this.getChallenges();
    this.getPurchasedChallenges();
  }

  async getChallenges() {
    try {
      const { data } = await get.challengeShortInfo();
      this.challenges = data;
    } catch (error) {
      console.log(error);
    }
  }

  async getPurchasedChallenges() {
    try {
      const { data } = await get.purchasedChallenges();
      this.purchasedChallenges = data;
    } catch (error) {
      console.log(error);
    }
  }

  get startingLanguage() {
    switch (this.$i18n.locale) {
      case "en-GB":
        return `en`;
      case "en-US":
        return `en`;
    }
    return this.$i18n.locale;
  }

  get Languages() {
    return [...new Set(this.challenges.map((challenge) => challenge.language))];
  }

  get filterChallengesByLanguage() {
    return this.challenges.filter(
      (challenge) => challenge.language === this.pickedLanguage
    );
  }

  translateLanguage(langCode: string) {
    const langDict: any = this.$t("languages-translated");
    return langDict[langCode];
  }
}
