
import { ChallengeShortInfo } from "@/interfaces/challenge";
import { Vue, Component, Prop } from "vue-property-decorator";
import vSelect from "vue-select";

@Component({
  components: {
    vSelect
  }
})
export default class ChallengeCard extends Vue {
  @Prop({})
  challenge!: ChallengeShortInfo;

  pickedCurrency = this.getCurrency

  get getCurrency(): string {
    let startingCurrency: string;
    switch (this.$i18n.locale) {
      case "pl":
        startingCurrency = "PLN";
        break
      case "en-GB":
        startingCurrency = "GBP";
        break
      case "en-US":
        startingCurrency = "USD";
        break
      default:
        startingCurrency = "GBP";
    }
    const isAvailable = this.challenge.prices.find((price)=>
      price.currency == startingCurrency
    )
    if(isAvailable) 
      return startingCurrency

    return this.challenge.prices[0].currency
  }

  get priceForCurrency(): string {
    const price = this.challenge.prices.filter((price) => price.currency === this.pickedCurrency);
    if(price.length > 0) {
      return price[0].price
    }
    return ""
  }

  get currencies() {
    return [...new Set(this.challenge.prices.map((price) => price.currency))];
  }

  goToCheckout() {
    this.$store.commit("setPayment", {
      id: this.challenge.id,
      title: this.challenge.title,
      prices: this.challenge.prices,
      currency: this.pickedCurrency,
      language: this.challenge.language
    });
    this.$router.push("/payment-challenge")
  }
}
